/** @jsx jsx */
import { jsx } from '@emotion/react'

import { success } from '../assets/styles/AboutTwo.styles'

const SuccessContent = () => {
  return (
    <section  css={success}>
      <div>
        <h1>Obrigado pelo <br/>seu interesse</h1>
       <p>Nosso time comercial entrará em contato com você</p>
      </div>
    </section>
  )
}

export default SuccessContent
